import { Link } from 'gatsby'
import React from 'react'
import Bg from '../assets/images/bg.png'
import Logo from '../assets/images/logo.png'
import AppleButton from '../assets/svgs/apple.svg'
import { APPLE_URL } from '../constants'
import STYLES from '../styles/styles'

const IndexPageComponent = ({
    language,
    policy,
    terms,
}: {
    policy: string
    terms: string
    language?: string
}) => {
    const languageCode = language ? `/${language}` : ''
    return (
        <>
            <img src={Bg} className="absolute w-full h-full opacity-10" />
            <div className="square w-80vw h-80vw sm:w-70vw sm:h-70vw md:w-50vw md:h-50vw xl:w-35vw xl:h-35vw rounded flex justify-center items-center flex-col relative">
                <img src={Logo} className="w-8/12 pb-20 md:pb-15" />
                <div className="w-full flex pt-6 justify-center">
                    <AppleButton
                        className="w-1/2 md:w-1/3 cursor-pointer bg-black rounded px-2 hover:shadow-2xl"
                        style={{ borderRadius: '130px' }}
                        onClick={() => window.location.assign(APPLE_URL)}
                    />
                </div>
            </div>
            <div className="relative mt-28 flex flex-col md:flex-row items-center justify-center text-white">
                <Link
                    to={`${languageCode}/legal/terms`}
                    className={`md:mr-10 ${STYLES.link}`}
                >
                    {terms}
                </Link>
                <Link
                    to={`${languageCode}/legal/policy`}
                    className={`mt-3 md:mt-0 ${STYLES.link}`}
                >
                    {policy}
                </Link>
            </div>
        </>
    )
}

export default IndexPageComponent
